const Challenge = ({ t }) => {
  return (
    <div className="w-full">
      <div
        id="expes"
        className="mx-auto flex flex-col gap-0 w-full justify-center items-center h-full p-10"
      >
        <div className="flex justify-start items-center w-full h-full z-50">
          <div className="flex flex-col md:flex-row-reverse w-full items-center">
            <div className="flex flex-col w-full md:w-2/3 gap-3 justify-start pt-20 md:p-10">
              <h2 className="text-xl md:text-2xl font-normal text-left text-[#2a2d2e] mt-0 mb-4 max-w-[650px] md:pl-4">
                {t("slostr-challenge-title")}
              </h2>
              <h3 className="md:pl-4 text-lg md:text-xl font-[200] text-left text-[#2a2d2e] mt-0 max-w-[650px]">
                {t("slostr-challenge-header")}
              </h3>
            </div>
            <div className="relative mt-5 md:mt-0 h-[300px] w-[calc(9/16*300px-20px)] md:h-[600px] md:w-[calc(9/16*600px-20px)]">
              <img
                src="https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/challenge.png"
                alt="Slostr app showcase"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Challenge;
