import { useState } from "react";
import ChallengeButton from "./ChallengeButtonRedirect";

const activityOptions = [
  {
    id: "climbing",
    icon: "/icons/icon-climbing.svg",
    label: "climbing-spots",
    description: "climbing-spots-explanation",
    mapLink: "/climbing-spots",
    welcomeImg:
      "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/climbing-map.jpg",
    ids: [111, 386, 320, 378],
  },
  {
    id: "bike",
    icon: "/icons/icon-bike.svg",
    label: "bike-itineraries",
    description: "bike-itineraries-explanation",
    mapLink: "/itineraries/bike",
    welcomeImg:
      "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/cycling-map.jpg",
    ids: [288, 159, 323, 333],
  },
  {
    id: "hike",
    icon: "/icons/icon-hike.svg",
    label: "hiking-itineraries",
    description: "hiking-itineraries-explanation",
    mapLink: "/itineraries/hiking",
    welcomeImg:
      "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/hiking-map.jpg",
    ids: [240, 228, 112],
  },
];

const Header = ({ t }) => {
  const [selectedActivity, setSelectedActivity] = useState(activityOptions[0]);

  return (
    <div
      className="w-full h-screen"
      style={{ backgroundImage: "linear-gradient(120deg, #499f71 0%, #71ad8d 100%)" }}
    >
      <div className="mx-auto flex flex-col gap-0 w-full justify-center items-center h-full">
        <div className="flex justify-center items-start md:items-center w-full h-full z-50">
          <div className="flex flex-col md:flex-row w-full">
            <div className="relative mt-5 md:mt-0 h-72 md:h-screen w-full ml-5 md:ml-0">
              <img
                src="https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/header.png"
                alt="Slostr app showcase"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="flex flex-col w-full gap-3 justify-center items-center md:items-start mt-5 md:mt-0">
              <div className="relative w-full h-[130px] flex items-center justify-center md:justify-start md:items-start">
                <img
                  src="/slostr-logo-black.svg"
                  alt="Slostr Logo"
                  width={300}
                  height={150}
                  className="object-contain slostr-logo"
                />
              </div>
              <h1 className="text-[1.3rem] md:text-[1.8rem] font-normal text-left text-white mb-4 max-w-[650px] pl-0 md:pl-4">
                {t("slostr-title")}
              </h1>
              <h2 className="pl-0 md:pl-4 text-lg md:text-xl font-[300] text-center md:text-left text-white mt-0 max-w-[650px]">
                {t("slostr-header")}
              </h2>
              <div className="pl-0 md:pl-4 h-fit w-full max-w-full relative justify-center items-start mt-5 md:mt-0">
                <div className="flex flex-row relative md:max-w-[250px] gap-2 rounded-[10px] justify-center items-center mt-2">
                  <a
                    href="https://apps.apple.com/be/app/slostr/id6590637281?itscg=30200&itsct=apps_box_badge&mttnsubad=6590637281"
                    style={{ display: "flex" }}
                  >
                    <img
                      src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/white/en-us?releaseDate=1722902400"
                      alt="Download on the App Store"
                      width={140}
                      height={40}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.slostr.Slostr&hl=en"
                    style={{ display: "flex" }}
                  >
                    <img
                      src="/GetItOnGooglePlay_Badge_Web_color_English.png"
                      alt="Download on the Play Store"
                      width={143}
                      height={41}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
