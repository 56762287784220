import React, { useState } from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import DirectionsIcon from "@mui/icons-material/Directions";

import { MyGallery} from '../../pages/blogs/utils'

export function transformImages(images: any) {

    const newImages =
      images.map((image: any) => ({
        type: "image",
        original: image,
        caption: image.caption || "",
      })) || [];


    return [...newImages];
  }

const POIModal = ({ isOpen, onClose, selectedFeature }) => {
    const [isFullscreen, setIsFullscreen] = useState(false);

  if (!selectedFeature) return null;

  // ✅ Extract Description Based on Language
  const getDescription = () => {
    const { description, description_fr, description_nl } = selectedFeature.properties;
    const language: string = "en"; // Replace with actual language detection logic
    return language === "fr" ? description_fr || description : language === "nl" ? description_nl || description : description;
  };

  // ✅ Extract Links from Additional Data
  const extractLinks = (additionalData) => {
    const urlRegex = /https?:\/\/[^\s<]+/g;
    let urls = additionalData.match(urlRegex) || [];
    const cleanUrl = (url) => {
      const invalidEndings = [")", ".", ",", ";", "!", "?", ":", "[", "]", "{", "}", "(", '"', "'"];
      while (invalidEndings.includes(url[url.length - 1])) {
        url = url.slice(0, -1);
      }
      return url;
    };
    return urls.map(cleanUrl);
  };
  let images = [];
  try {
    const parsedImages = JSON.parse(selectedFeature.properties.images);
    if (Array.isArray(parsedImages)) {
      images = parsedImages.filter((image) => image && image !== "None");
    }
  } catch (e) {
    console.error("Failed to parse images:", e);
  }
  return (
    <div className={`absolute w-[400px] h-full z-[1000] left-[20px] top-[88px] ${isOpen ? 'visible' : 'hidden'}`}>
      <Box sx={styles.modalContainer}>
        {/* Close Button */}
        <IconButton sx={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>

        {/* POI Name */}
        <Typography variant="h5" sx={styles.poiHeader}>
          {selectedFeature.properties.name}
        </Typography>

        {/* Image Carousel */}
        {images.length > 0 && (
          <MyGallery
          items={transformImages(images)}
                          isFullscreen={isFullscreen}
                          setIsFullscreen={setIsFullscreen}
                          />
        )}

        {/* Description */}
        <Typography sx={styles.poiDescription}>{getDescription()}</Typography>

        {/* Links */}
        {selectedFeature.properties.additional_data &&
          extractLinks(selectedFeature.properties.additional_data).map((link, index) => (
            <Typography key={index} sx={styles.link} onClick={() => window.open(link, "_blank")}>
              More info: {link}
            </Typography>
          ))}

        {/* Action Buttons */}
        <Box sx={styles.buttonContainer}>
          {selectedFeature.properties.source && (
            <Button
              variant="outlined"
              startIcon={<InfoIcon />}
              onClick={() => window.open(selectedFeature.properties.url || selectedFeature.properties.source, "_blank")}
            >
              Source
            </Button>
          )}

          {/* <Button
            variant="contained"
            startIcon={<DirectionsIcon />}
            onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&destination=${selectedFeature.geometry.coordinates[1]},${selectedFeature.geometry.coordinates[0]}`)}
          >
            Get Directions
          </Button> */}
        </Box>
      </Box>
    </div>
  );
};

// ✅ Styles
const styles = {
  modalContainer: {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    m:2,
    borderRadius: 2,
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    top: 25,
    right: 10,
  },
  poiHeader: {
    fontWeight: "bold",
    mb: 2,
  },
  carouselItem: {
    textAlign: "center",
  },
  image: {
    width: "100%",
    height: "auto",
    borderRadius: 8,
  },
  poiDescription: {
    mt: 2,
  },
  link: {
    color: "blue",
    cursor: "pointer",
    mt: 1,
    textDecoration: "underline",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    mt: 3,
  },
};

export default POIModal;
