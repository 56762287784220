import React, { useEffect, useRef, useState, useContext } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Drawer from '@mui/material/Drawer';
import * as turf from "@turf/turf";
import LaunchIcon from "@mui/icons-material/Launch";
import { useTranslation } from "react-i18next";
import polyline from "@mapbox/polyline";
import { Button, FormControlLabel, Switch, IconButton } from "@mui/material";
import { Card, CardContent, Typography, Stack, Box } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { Close, Diversity1 } from "@mui/icons-material";
import Chart from "chart.js/auto";
import { Tooltip as TooltipUI } from "@nextui-org/tooltip";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import {
 Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure
} from "@nextui-org/react";
import Navbar from "../components/main/Navbar";
import POIModal from "../components/map/POIModal";

import LoadingPage from "./Loading";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { AuthContext } from "../components/authentification/AuthContext";
import CreateAccountForm from "../components/authentification/CreateAccountForm";


const POISpotsMap: React.FC<any> = ({
  map,
  mapContainerRef,
  allLayers,
  setAllLayers,
  allMarkers,
  setAllMarkers,
  mapAnimation,
  setMapAnimation,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { user } = authContext || {};

  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const markersdistanceRef = useRef([]);
  const [elevationSummary, setElevationSummary] = useState<any[]>([
    null,
    null,
    null,
  ]);
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);


  useEffect(() => {
    if (map) {
      if (map.isStyleLoaded()) {
        setMapInstance(map);
      } else {
        map.on("load", () => {
          setMapInstance(map);
        });
      }

      map.off("load");
    }
  }, [map, mapContainerRef]);

  useEffect(() => {
    const resetting = async (map: any) => {
      await cleanUpMap(map);

      map.off("click");
      map.off("mouseenter");
      map.off("mouseleave");
      map.off("moveend");
      map.off("move");
      map.off("style.load");
      map.off("idle");

      map.resize();
      map.easeTo({ center: [7, 48], zoom: 4.3 });
    };

    if (mapInstance) {
      resetting(mapInstance);
    }
  }, [mapInstance]);

  const desiredStyleUrl = "mapbox://styles/vladke99/cm33k6g55002o01nwhohygudi";
  const desiredStyle = "cm33k6g55002o01nwhohygudi";

  const resetStyleAndFeatures = async (mapInstance: any) => {
    mapInstance.off("click");
    mapInstance.off("mouseenter");
    mapInstance.off("mouseleave");
    mapInstance.off("move");
    mapInstance.off("moveend");
    mapInstance.off("style.load");
    mapInstance.off("render");

    // if (
    //   !mapInstance.getStyle().sprite ||
    //   !mapInstance.getStyle().sprite.includes(desiredStyle)
    // ) {
    //   mapInstance.setStyle(desiredStyleUrl);
    // }
  };

  const removeSourcesAndLayers = async (
    mapInstance,
    layerNames = [],
    sourceNames = []
  ) => {
    try {
      const allLayers = mapInstance.getStyle().layers;
      const allSources = Object.keys(mapInstance.getStyle().sources);

      console.log("All layers before cleanup:", allLayers);

      // First, remove all layers associated with "slostr" or specified in `layerNames`
      allLayers.forEach((layer) => {
        if (
          (layer.id.includes("slostr") || layerNames.includes(layer.id)) &&
          mapInstance.getLayer(layer.id)
        ) {
          console.log(`Removing layer: ${layer.id}`);
          mapInstance.removeLayer(layer.id);
        }
      });

      // Then, remove all sources associated with "slostr" or specified in `sourceNames`
      allSources.forEach((sourceId) => {
        if (
          (sourceId.includes("slostr") || sourceNames.includes(sourceId)) &&
          mapInstance.getSource(sourceId)
        ) {
          console.log(`Removing source: ${sourceId}`);
          mapInstance.removeSource(sourceId);
        }
      });

      // Finally, remove all markers
      if (mapInstance._markers && Array.isArray(mapInstance._markers)) {
        console.log(`Removing ${mapInstance._markers.length} markers`);
        mapInstance._markers.forEach((marker) => {
          marker.remove();
        });
        // Clear the markers array to ensure there are no lingering references
        mapInstance._markers = [];
      }
    } catch (error) {
      console.error("Error during layer, source, or marker removal:", error);
    }
  };

  const poiLayers = [
    { id: "slostr-waterPointsLayer", sourceLayer: "watertaps", icon: "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/waterIcon.png" },
    // { id: "slostr-trainPointsLayer", sourceLayer: "trains", icon: "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/trainIcon.png" },
    { id: "slostr-shelterPointsLayer", sourceLayer: "shelters", icon: "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/shelterIcon.png" },
    { id: "slostr-housePointsLayer", sourceLayer: "houses", icon: "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/houseIcon.png" },
    { id: "slostr-accommodationPointsLayer", sourceLayer: "accommodations", icon: "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/accomodationIcon.png" },
    { id: "slostr-campgroundPointsLayer", sourceLayer: "campgrounds", icon: "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/campIcon.png" },
    { id: "slostr-markersPointsLayer", sourceLayer: "markers", icon: "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/markerIcon.png" },
  ];

  const cleanUpMap = async (mapInstance: any) => {
    if (mapAnimation !== null) {
      cancelAnimationFrame(mapAnimation);
      setMapAnimation(null);
    }

    mapInstance.off("click");
    mapInstance.off("mouseenter");
    mapInstance.off("mouseleave");
    mapInstance.off("move");
    mapInstance.off("moveend");
    mapInstance.off("style.load");
    mapInstance.off("render");

    setTimeout(() => {
      if (allMarkers.length > 0) {
        allMarkers.forEach(([marker, markerName]: any) => marker.remove());
        setAllMarkers([]);
      }

      const markerDivs = document.querySelectorAll(".mapboxgl-marker");
      markerDivs.forEach((marker) => {
        marker.remove();
      });
      removeSourcesAndLayers(mapInstance, allLayers, allLayers);
      setAllLayers([]);
      setIsLoading(false);
      const el = document.createElement("div");
      el.className = `marker-small`; // Set class based on transportation mode
      // @ts-ignore
      const marker = new mapboxgl.Marker(el)
        // @ts-ignore
        .setLngLat([0, 0])
        .addTo(map);
      // @ts-ignore
      markersdistanceRef.current.push(marker);


        mapInstance.addSource("slostr-trains", {
            type: "vector",
            tiles: [
                'https://slostr.s3.fr-par.scw.cloud/tiles/trains/{z}/{x}/{y}.pbf'
            ],
            minzoom: 6.5,
            maxzoom: 20,
        });

        mapInstance.loadImage(
            'https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/trainIcon.png',
            (error, image) => {
                if (error) throw error;

                mapInstance.addImage('stations_eu', image);

                // Add Train Stations Layer
                mapInstance.addLayer({
                    id: "slostr-trainIcons",
                    type: "symbol",
                    source: "slostr-trains",
                    "source-layer": "stations_eu",
                    layout: {
                        "icon-image": "stations_eu",
                        "icon-size": 0.025,
                        "icon-allow-overlap": true,
                        "icon-anchor": "center",
                        "visibility": "visible",
                    },
                });

                // Add Train Lines Layer
                mapInstance.addLayer({
                    id: "slostr-trainlines",
                    type: "line",
                    source: "slostr-trains",
                    "source-layer": "trainlines_eu",
                    paint: {
                        "line-color": "#000000",
                        "line-width": 0.5,
                        "line-opacity": 0.8,
                    },
                    layout: {
                        "visibility": "visible",
                    },
                });

                // Show station name on click
                mapInstance.on('click', "slostr-trainIcons", (e) => {
                    const features = e.features[0];
                    if (features) {
                        const stationName = features.properties.name || "Unknown Station";
                        new mapboxgl.Popup()
                            .setLngLat(e.lngLat)
                            .setHTML(`<strong style="padding: 10px">${stationName}</strong>`)
                            .addTo(mapInstance);
                    }
                });

                // Change cursor to pointer when hovering over stations
                mapInstance.on('mouseenter', "slostr-trainIcons", () => {
                    mapInstance.getCanvas().style.cursor = 'pointer';
                });

                mapInstance.on('mouseleave', "slostr-trainIcons", () => {
                    mapInstance.getCanvas().style.cursor = '';
                });

            }
        );
    }
    , 500);

    setTimeout(() => {

          mapInstance.addSource("slostr-pois", {
            type: "vector",
            tiles: [
              'https://slostr.s3.fr-par.scw.cloud/tiles/pois_v3/{z}/{x}/{y}.pbf'
            ],

            minzoom: 0,
            maxzoom: 11,
          });

          poiLayers.forEach(({ id, sourceLayer, icon }) => {

          mapInstance.loadImage(
            icon,
            (error, image) => {
              if (error) {
                throw error};

              mapInstance.addImage(sourceLayer, image);
            mapInstance.addLayer({
              id,
              type: "symbol",
              source: "slostr-pois",
              "source-layer": sourceLayer,
              layout: {
                "icon-image": sourceLayer,
                "icon-size": sourceLayer === 'watertaps' ? 0.03 : 0.04,
                "icon-allow-overlap": sourceLayer === 'watertaps' ? false : true,
                "icon-anchor": "center",
                "visibility": "visible",
              },
            });
          });
        })

        mapInstance.on("click", (event) => {
          const features = mapInstance.queryRenderedFeatures(event.point, {
            layers: poiLayers.map((layer) => layer.id),
          });

          if (features.length > 0) {
            setSelectedFeature(features[0]); // Set selected feature
            setIsModalOpen(true); // Open modal
          }
        });

      }

    , 700);
  };

  useEffect(() => {
    if (!mapInstance) return;

    resetStyleAndFeatures(mapInstance);

    mapInstance.resize();
    try {
      mapInstance.flyTo({ center: [7, 48], zoom: 4.3, speed: 2 });
    } catch (error) {
      console.log("error in setting center and zoom", error);
    }
  }, [mapInstance]);

  useEffect(() => {
    if (mapInstance) return;

    const mapContainerDiv = document.getElementById("map");
    if (mapContainerDiv && mapContainerRef.current) {
      if (mapContainerDiv !== mapContainerRef.current.parentNode) {
        mapContainerDiv.innerHTML = ""; // Clear existing content
        mapContainerDiv.appendChild(mapContainerRef.current);
      }
    }
  }, [mapInstance]);

  useEffect(() => {
    if (!mapInstance) return;

    const setLanguage = () => {
      const style = mapInstance.getStyle();
      if (style && style.layers) {
        style.layers.forEach((layer) => {
          if (
            layer.type === "symbol" &&
            layer.layout &&
            layer.layout["text-field"]
          ) {
            // Adjust the text-field for each label layer
            mapInstance.setLayoutProperty(layer.id, "text-field", [
              "coalesce",
              ["get", `name_${i18n.language}`], // Try to get the localized name
              ["get", "name"], // Fallback to the default name
            ]);
          }
        });
      }
    };

    setLanguage();
  }, [i18n.language]);

  return (
    <div className="w-full h-screen relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start">
      <Navbar />

      <div id="map" className="absolute left-0 w-full h-[calc(100vh)]" />
      {isLoading && (
        <div className=" z-[10000] absolute left-0 w-full h-[calc(100vh)] bg-white">
          <LoadingPage />
        </div>
      )}
      <POIModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} selectedFeature={selectedFeature} />

    </div>
  );
};


export default POISpotsMap;
