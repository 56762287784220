// AuthProvider.tsx
import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { fetchCurrentUser } from '../../utils/api/api';
import { useNavigate } from 'react-router-dom';
import posthog from "posthog-js";

interface AuthContextType {
  isAuthenticated: boolean;
  user: any;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setUser: (user: any) => void;
  logout: () => void; // Add logout function to the context type
  loginMessage: string;
  isLoading: boolean;
  fetchCurrentUser: () => Promise<any>;

}

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [loginMessage, setLoginMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const fetchAndSetCurrentUser = async () => {
    try {
      const userData = await fetchCurrentUser();
      if (userData) {
        if (userData.is_test_user) {
          setIsAuthenticated(true);  // Set authenticated if user is a test user
        } else {
          setIsAuthenticated(false);
          navigate('/beta-test');
        }
        setUser(userData);
        setLoginMessage(`Logged in as ${userData.name}`);
      } else {
        setIsAuthenticated(false);
        setUser(null);
        setLoginMessage("Access denied: not a test user.");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch user:", error);
      setIsAuthenticated(false);
      setUser(null);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      fetchAndSetCurrentUser();

      if (user && user.id) {
        posthog.identify(user.id, {
          email: user.email,
          username: user.username,
        })}
    } else {
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  }, [isAuthenticated]);



  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
    navigate('/'); // Optional: navigate to login or another page
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, setIsAuthenticated, setUser, logout, loginMessage, isLoading, fetchCurrentUser: fetchAndSetCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
