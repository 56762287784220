import React, { useState } from "react";
import { Dialog, Box, Button, IconButton, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from 'react-i18next';
import Lottie from "lottie-react";
import supportAnimation from "./animations/sloth.json"; // Replace with your actual Lottie file

const KoFiButton = () => {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* Floating "Support Us" Button */}
      <Button
        variant="contained"
        sx={{
          position: "fixed",
          bottom: 41,
          left: 60,
          backgroundColor: "#2DAA9E",
          color: "#fff",
          fontWeight: "bold",
          borderRadius: "50px",
          border: "2px solid white",
          padding: "5px 20px",
          zIndex: 1300,
          "&:hover": { backgroundColor: "#00897b" },
        }}
        // endIcon={<FaHeart  color="#F88379"/>        }
        onClick={() => setOpen(true)}
      >
        {t('support-us')} <p className="text-xl ml-2">💪</p>
      </Button>

      {/* MUI Modal */}
      <Dialog open={open} onClose={() => setOpen(false)}
      maxWidth={'sm'}
      >
      <DialogTitle>
      <div className="flex flex-row gap-3">
      {t('support-us')} <p className="text-xl">💪</p>
      </div>
      </DialogTitle>
      <DialogContent>
          {/* Close Button */}
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ position: "absolute", top: 5, right: 5 }}
          >
            <CloseIcon />
          </IconButton>
          <div className="flex flex-col gap-2 p-2">
          <p className="">{t('payments.intro')}</p>
          <p className="">{t('payments.support')}</p>
          <div className="flex flex-row gap-2 items-center">
          <p className="">{t('payments.team')}</p>
          <Lottie animationData={supportAnimation} style={{ width: 50, height: 50 }} />
</div>
          </div>

          {/* Ko-Fi Iframe */}
          <iframe
            id="kofiframe"
            src="https://ko-fi.com/slostr/?hidefeed=true&widget=true&embed=true&preview=true"
            style={{ border: "none", width: "100%", padding: "4px", background: "#f9f9f9" }}
            height="580"
            title="slostr"
          ></iframe>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default KoFiButton;
