import React, { useState } from "react";
import { Box, Typography, IconButton, Grid, Button } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Instagram from "@mui/icons-material/Instagram";
import ReactPlayer from "react-player";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";

const videos = [
  {
    id: 322,
    videoUri: "https://slostr.s3.fr-par.scw.cloud/interviews/final/5.thomas-tandem.mp4",
    thumbnailUri: "https://slostr.s3.fr-par.scw.cloud/interviews/final/5.thomas-tandem.jpg",
  },
  {
    id: 368,
    videoUri: "https://slostr.s3.fr-par.scw.cloud/interviews/final/4.gilles_pyrenees.mov",
    thumbnailUri: "https://slostr.s3.fr-par.scw.cloud/interviews/final/4.gilles_pyrenees.jpg",
  },
  {
    id: 159,
    videoUri: "https://slostr.s3.fr-par.scw.cloud/interviews/final/3.sibylle_nordkapp.mp4",
    thumbnailUri: "https://slostr.s3.fr-par.scw.cloud/interviews/final/3.sibylle_nordkapp.jpg",
  },
  {
    id: 334,
    videoUri: "https://slostr.s3.fr-par.scw.cloud/interviews/final/2.dorsan_mischabel.mp4",
    thumbnailUri: "https://slostr.s3.fr-par.scw.cloud/interviews/final/2.dorsan_mischabel.jpg",
  },
  {
    id: 67,
    videoUri: "https://slostr.s3.fr-par.scw.cloud/interviews/final/1.anna_francois_arco.mp4",
    thumbnailUri: "https://slostr.s3.fr-par.scw.cloud/interviews/final/1.anna_francois_arco.jpg",
  },
];

const VideoPlayerItem = ({ item, activeVideoId, setActiveVideoId }) => {
  const isActive = activeVideoId === item.id;

  const handlePlay = () => {
    setActiveVideoId(item.id);
  };

  return (
    <Box
      sx={{
        width: 150,
        height: 270,
        borderRadius: 2,
        overflow: "hidden",
        backgroundColor: "black",
        position: "relative",
      }}
    >
      {isActive ? (
        <ReactPlayer
          url={item.videoUri}
          playing
          loop
          controls
          width="100%"
          height="100%"
        />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            backgroundImage: `url(${item.thumbnailUri})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={handlePlay}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#ffffff90",
            }}
          >
            <PlayCircleIcon fontSize="large" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

const VideoPlayerList = () => {
  const [activeVideoId, setActiveVideoId] = useState(null);
  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4, // Define visible items for large screens
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };



  return (
<div style={{
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  paddingTop: "0px",
  maxWidth: "700px",
  margin: "0 auto",
  width: "100%"
}}>      {/* Section Title */}
      <h2 className="text-xl md:text-2xl font-normal text-center text-[#2a2d2e] mt-8 mb-2 max-w-[750px]">
        {t("landing-interview-title")}
      </h2>
      <h3 className="max-w-[750px] self-center text-lg md:text-xl text-center text-[#2a2d2e] font-[200] mb-4">
      {t("landing-interview-description")}
      </h3>

      {/* Video List */}
      <Carousel
        responsive={responsive}
        infinite={false}
        autoPlay={false}
        containerClass="carousel-container"
        itemClass="carousel-item-padding-40-px"
        customTransition="transform 300ms ease-in-out"
        focusOnSelect={true}
        centerMode={false}
        // showDots={true}
        arrows
      >
        {videos.map((item) => (
          <Box
          key={item.id}
          sx={{
            width: "150px", // Set a fixed width for each carousel item
            flex: "none", // Ensure it doesn't stretch
            margin: "0 auto",
            marginBottom: '30px'
          }}
        >
          <VideoPlayerItem
            item={item}
            activeVideoId={activeVideoId}
            setActiveVideoId={setActiveVideoId}
          />
        </Box>
        ))}
      </Carousel>

      {/* Instagram Button */}
      <Box sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          className="!rounded-[10px]"
          endIcon={<Instagram />}
          onClick={() => window.open("https://instagram.com/goslostr", "_blank")}
        >
          {t("landing-interview-cta")}
        </Button>
      </Box>
    </div>
  );
};

export default VideoPlayerList;
