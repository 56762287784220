import HorizontalBanner from "./HorizontalBanner";

const Expes = ({ t, blogs }) => {
  return (
    <div className="w-full">
      <div
        id="product-showcase"
        className="mx-auto flex flex-col gap-0 w-full justify-center items-center h-full"
      >
        <div className="flex justify-center md:justify-start items-center w-full h-full z-50">
          <div className="flex flex-col-reverse md:flex-row w-full pt-10 items-center md:items-start">
            <div className="flex flex-col w-full md:w-2/3 gap-3 justify-start p-10">
              <h2 className="text-xl md:text-2xl font-normal text-left text-[#2a2d2e] mt-0 mb-4 max-w-[650px] md:pl-10">
                {t("slostr-expes-title")}
              </h2>
              <h3 className="md:pl-10 text-lg md:text-xl font-[200] text-left text-[#2a2d2e] mt-0 max-w-[650px]">
                {t("slostr-expes-header")}
              </h3>
              <div className="flex flex-row gap-4 md:pl-10">
                <img src="/icons/icon-bike.svg" alt="Cycling icon" className="w-[30px] h-[30px]" />
                <img src="/icons/icon-hike.svg" alt="Hiking icon" className="w-[30px] h-[30px]" />
                <img src="/icons/icon-climbing.svg" alt="Climbing icon" className="w-[30px] h-[30px]" />
                <img src="/icons/icon-surf.svg" alt="Surfing icon" className="w-[30px] h-[30px]" />
                <img src="/icons/icon-train.svg" alt="Train icon" className="w-[30px] h-[30px]" />
              </div>
              <HorizontalBanner blogs={blogs} />
            </div>
            <div className="relative mt-0 h-[400px] w-[calc(9/16*400px-20px)] md:h-[600px] md:w-[calc(9/16*600px-20px)]">
              <img
                src="https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/expes.png"
                alt="Slostr app showcase"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expes;
