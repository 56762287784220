import { useState } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from "react-router-dom";

const faqData = [
  {
    questionKey: "faq.app_free.question",
    answerKey: "faq.app_free.answer",
  },
  {
    questionKey: "faq.web_version.question",
    answerKey: "faq.web_version.answer",
  },
  {
    questionKey: "faq.komoot_difference.question",
    answerKey: "faq.komoot_difference.answer",
  },
  {
    questionKey: "faq.polarsteps_difference.question",
    answerKey: "faq.polarsteps_difference.answer",
  },
  // {
  //   questionKey: "faq.filtering_trips.question",
  //   answerKey: "faq.filtering_trips.answer",
  // },
  {
    questionKey: "faq.feedback.question",
    answerKey: "faq.feedback.answer",
    isFeedback: true, // Mark this as a feedback-related question
  },
  {
    questionKey: "faq.new_features.question",
    answerKey: "faq.new_features.answer",
  },
  // {
  //   questionKey: "faq.sponsored_trips.question",
  //   answerKey: "faq.sponsored_trips.answer",
  // },
  {
    questionKey: "faq.impact.question",
    answerKey: "faq.impact.answer",
  },
  {
    questionKey: "faq.support_us.question",
    answerKey: "faq.support_us.answer",
  },
];

export default function FAQ() {
  const [openItems, setOpenItems] = useState<number[]>([]);
  const { t } = useTranslation();

  const toggleItem = (index: number) => {
    setOpenItems((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  return (
    <div className="max-w-2xl mx-auto py-4 px-4">
      <h1 className="text-3xl font-bold text-center text-primary mb-4">
        {t("faq.title", "Frequently Asked Questions")}
      </h1>
      <dl className="space-y-6 divide-y divide-gray-200">
        {faqData.map((item, index) => (
          <div key={index} className="pt-6">
            <dt className="text-lg">
              <button
                onClick={() => toggleItem(index)}
                className="text-left w-full flex justify-between items-start text-gray-400"
                aria-expanded={openItems.includes(index)}
              >
                <span className="font-medium text-gray-900">
                  {t(item.questionKey)}
                </span>
                <span className="ml-6 h-7 flex items-center">
                  <KeyboardArrowDownIcon
                    className={`${
                      openItems.includes(index) ? "-rotate-180" : "rotate-0"
                    } h-6 w-6 transform transition-all duration-300 ease-in-out`}
                    aria-hidden="true"
                  />
                </span>
              </button>
            </dt>
            <dd
              className={`mt-2 pr-12 transition-all duration-300 ease-in-out ${
                openItems.includes(index)
                  ? "max-h-96 opacity-100"
                  : "max-h-0 opacity-0 overflow-hidden"
              }`}
            >
              <p className="text-base text-gray-500">{t(item.answerKey)}</p>
              {item.isFeedback && (
                <p className="mt-2 text-primary underline">
                  <Link to="/feedback" className="text-primary">{t("faq.feedback_link", "Donner mon avis")}</Link>
                </p>
              )}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
