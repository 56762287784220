import React from "react";
import { Card, CardMedia, CardContent, Typography, Box, Avatar, Stack, AvatarGroup } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import HikingIcon from "@mui/icons-material/Hiking";
import { useTranslation } from "react-i18next";
import { calculateShortLabel } from "../../utils/utils";

function getLocalizedField(blog: any, field: string, lang: string) {
  const suffix = lang ? `_${lang}` : "";
  return blog[field + suffix] && blog[field + suffix] !== "" ? blog[field + suffix] : blog[field];
}

const BlogCard = ({ blog }: { blog: any }) => {
  const { t, i18n } = useTranslation();

  return (
    <Card sx={styles.card}>
      {/* Blog Image */}
      <Box sx={styles.imageContainer}>
        <CardMedia
          component="img"
          height="160"
          image={blog.header_image_thumbnail || "/welcome_img.png"}
          alt={blog.title}
          sx={styles.image}
        />

        {/* Overlay Content */}
        <Box sx={styles.overlay}>
          {/* Top Section: Author */}
          <Stack direction="column">
          <Stack direction="row" justifyContent="space-between" sx={styles.topOverlay}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar src={blog.author?.profile_picture || "/SlowTrav_icon.png"} sx={{ width: 24, height: 24 }} />
              <Typography sx={styles.authorText}>
                {blog.author?.first_name} {blog.author?.last_name}
              </Typography>
            </Stack>

            {/* Time Icon */}
            <Stack direction="row" spacing={0.5} alignItems="center">
              <AccessTimeIcon sx={{ fontSize: 16, color: "white" }} />
              <Typography sx={styles.infoText}>{calculateShortLabel(blog.number_of_days, t)}</Typography>
            </Stack>
          </Stack>
          <div className="flex justify-end items-end mt-1">
          <AvatarGroup max={4}  sx={{
        '& .MuiAvatar-root': { width: 20, height: 20, fontSize: 15 },
      }}>
        {blog.contributors.map((contributor: any) => (
            <Avatar alt={`${contributor.first_name || ''} ${contributor.last_name || ''}`} src={contributor.profile_picture} sx={{ width: 20, height: 20 }}> {!contributor.profile_picture && (
                `${contributor.first_name ? contributor.first_name[0].toUpperCase() : ''}`
              )} </Avatar>
              ))}
    </AvatarGroup>
          </div>
          </Stack>

          {/* Bottom Section: Title & Distance */}
          <Stack direction="row" justifyContent="space-between" width={'100%'} sx={styles.bottomOverlay}>
            {/* Blog Title */}
            <Typography sx={styles.blogTitle}>
              {getLocalizedField(blog, "title", i18n.language).length > 43
                ? blog.title.slice(0, 43) + ".."
                : blog.title}
              {/* {getLocalizedField(blog, "title", i18n.language).length > 43
                ? getLocalizedField(blog, "title", i18n.language).slice(0, 43) + ".."
                : getLocalizedField(blog, "title", i18n.language)} */}
            </Typography>

            {/* Distance Display */}
            <div className="w-[100px] flex justify-end items-end">
            {blog.activities.some((activity: any) => activity.name === "bike") && blog.stats?.bike?.distance !== undefined ? (
              <Stack direction="row" spacing={0.5} alignItems="center">
                <DirectionsBikeIcon sx={{ fontSize: 16, color: "white" }} />
                <Typography sx={styles.infoText}>{blog.stats.bike.distance.toFixed(0)} km</Typography>
              </Stack>
            ) : blog.activities.some((activity: any) => activity.name === "hiking") && blog.stats?.hike?.distance !== undefined ? (
              <Stack direction="row" spacing={0.5} alignItems="center">
                <HikingIcon sx={{ fontSize: 16, color: "white" }} />
                <Typography sx={styles.infoText}>{blog.stats.hike.distance.toFixed(0)} km</Typography>
              </Stack>
            ) : null}
            </div>
          </Stack>
        </Box>
      </Box>

      {/* Blog Details
      <CardContent sx={styles.cardContent}>
        <Typography variant="body2" color="text.secondary">
          {getLocalizedField(blog, "summary", i18n.language)}
        </Typography>
      </CardContent> */}
    </Card>
  );
};

// ✅ Styles
const styles = {
  card: {
    width: 240,
    borderRadius: 2,
    overflow: "hidden",
    boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
  },
  imageContainer: {
    position: "relative",
  },
  image: {
    width: "100%",
    height: 160,
    objectFit: "cover",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.3)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "8px 12px",
  },
  topOverlay: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bottomOverlay: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: '100%'
  },
  blogTitle: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "left",
    width: '100%'
  },
  authorText: {
    fontSize: 12,
    color: "white",
  },
  infoText: {
    fontSize: 12,
    color: "white",
  },
  cardContent: {
    padding: 1.5,
  },
};

export default BlogCard;
