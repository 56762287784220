import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import { Box} from "@mui/material";
import Card from "../blog/CardSmall";
import { fetchBlogsByIds } from '../../utils/api/api';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const HorizontalBanner: any = ({blogs}) => {

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1200, min: 866 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 866, min: 0 },
      items: 1,
    },
  };


    return (
<div style={{
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  maxWidth: "800px",
  margin: "0 auto",
  width: "100%",
}}
className='pt-0 md:pt-[80px]'>

      {blogs.length > 0 ? (
    <Carousel
    responsive={responsive}
    infinite={true}
    autoPlay={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
    customTransition="transform 300ms ease-in-out"
    focusOnSelect={true}
    centerMode={false}
    // showDots={true}
    arrows
>
    {blogs.map((item) => (
        <Box
            key={item.id}
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                margin: "0 auto",
                marginBottom: "30px",
            }}
        >
            <Link to={`/${item.author?.username || "unknown"}/${item.id}`}>
                <Card blog={item} />
            </Link>
        </Box>
    ))}
</Carousel>
) : (
    <p>No blogs found</p> // 🔍 Debugging output
)}

        </div>
    );
};

export default HorizontalBanner;

