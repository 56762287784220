import React, { FunctionComponent, useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import Badge from '@mui/material/Badge';
import LogoutIcon from '@mui/icons-material/Logout';
import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button, cn} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { MdOutlineFeedback } from "react-icons/md";
import { TiHome } from "react-icons/ti";
import { FaMapMarkerAlt } from "react-icons/fa";

import { AuthContext } from '../authentification/AuthContext';
import { useNotification } from '../NotificationContext';





const UserDropdown: FunctionComponent<any> = ({user}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { logout } = authContext || {};
  const { notificationCount } = useNotification();
  const [showDropdown, setShowDropdown] = useState(false);
  const userdropdownRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();


  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (userdropdownRef.current && !userdropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [userdropdownRef]);

  const handleLogout = () => {
    logout?.();
  };


  return (
    <div className="relative flex flex-col items-end justify-start pl-[25px] text-left text-base text-gray font-text-small">
      <div className="flex flex-row items-center justify-start relative gap-[10px] font-text-small text-sm [&_.frame-container]:hover:flex cursor-pointer"
      onClick={toggleDropdown}
      ref={userdropdownRef}
>

        <Dropdown>
      <DropdownTrigger>
      <Badge badgeContent={notificationCount} color="primary">
        {user?.profile_picture ? (
        <img
        className="w-[45px] relative rounded-[50%] h-[45px] object-cover z-[0]"
        alt=""
        src={user?.profile_picture? user?.profile_picture : '/SlowTrav-icon.png'}
      />
        ) :
<Avatar>{`${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`}</Avatar>
        }
        </Badge>
      </DropdownTrigger>
      <DropdownMenu variant="faded" aria-label="Dropdown menu with icons">
        <DropdownItem
          key="new"
          startContent={<Badge badgeContent={notificationCount} color="primary">
          <img
            className="w-6 relative h-6 overflow-hidden shrink-0"
            alt=""
            src="/icons/iconaccount.svg"
          />
          </Badge>}
          onClick={() => navigate('/settings')}
        >
          {t('user-profile')}
        </DropdownItem>
        <DropdownItem
          key="edit"
          startContent={<img
            className="w-6 relative h-6 overflow-hidden shrink-0 transform scale-150"
            alt=""
            src="/route-icon-b.svg"
          />}
          onClick={() => navigate(user?.is_municipal ? `/municipal-content/${user?.username}` : `/${user?.username}`)}
        >
          {t('user-trips')}
        </DropdownItem>
        {user && user.username === 'vlad.travels' && (
        <DropdownItem
        key="edit"
        startContent={<FaMapMarkerAlt size={24} />}
        onClick={() => navigate(`/add-official-trip`)}
      >
        {t("add-official-trip")}
      </DropdownItem>
        )}
        <DropdownItem
          key="edit"
          startContent={<TiHome size={20} />}
          onClick={() => navigate(`/feed`)}
        >
          {t("nav-feed")}
        </DropdownItem>
        <DropdownItem
                key="feedback"
                description={t('feedback-info')}
                startContent={<MdOutlineFeedback size={24} />}
                onClick={() => navigate("/feedback")}
              >
                {t('feedback')}
              </DropdownItem>
        <DropdownItem
          key="delete"
          className="text-danger"
          color="danger"
          startContent={<LogoutIcon />}
          onClick={handleLogout}
        >
          {t('user-logout')}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
      </div>
    </div>
  );
};

export default UserDropdown;
