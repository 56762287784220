const Integrations = ({ t }) => {
  return (
    <div className="w-full">
      <div
        id="expes"
        className="mx-auto flex flex-col gap-0 w-full justify-center items-center h-full"
      >
        <div className="flex justify-start items-center w-full h-full z-50">
          <div className="flex flex-col md:flex-row w-full pt-10">
            <div className="flex flex-col w-full md:w-2/3 gap-3 justify-start md:p-10 p-3">
              <h2 className="text-xl md:text-2xl font-normal text-left text-[#2a2d2e] mt-0 mb-4 max-w-[650px] md:pl-20">
                {t("slostr-services-title")}
              </h2>
              <h3 className="md:pl-20 text-lg md:text-xl font-[200] text-left text-[#2a2d2e] mt-0 max-w-[750px]">
                {t("slostr-services-header")}
              </h3>
              <div className="md:ml-20 relative mt-10 md:mt-0 md:w-[700px] h-[180px]">
                <img
                  src="https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/map-ex.png"
                  alt="Example of combined trip"
                  className="object-cover rounded-[15px] w-full h-full"
                />
              </div>
            </div>
            <div className="w-full flex justify-center items-center">
              <div className="relative mt-10 md:mt-0 md:h-[400px] w-[140px] justify-center items-center">
                <img
                  src="https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/integrations.png"
                  alt="Integrated outdoor services"
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integrations;
