import { Link } from "react-router-dom";


const Maps = ({ t }) => {
  return (
    <div className="w-full flex justify-center items-center"
    >
      <div id="expes" className="flex flex-col items-center text-center w-full md:p-10">
        <h2 className="text-xl md:text-2xl font-normal text-[#2a2d2e] p-10">
          {t("slostr-maps-title")}
        </h2>
        <div className="flex flex-col md:flex-row gap-10 justify-between">
          {/* Bikepacking */}
          <Link to={"/itineraries/bike"} className="shadow-lg rounded-[15px] p-5 hover:scale-105 transition-all">
            <div className="flex flex-col items-center cursor-pointer w-[350px]">
              <div className="w-[80px] h-[80px] md:w-[100px] md:h-[100px] flex justify-center items-center bg-primary rounded-full">
                <img
                  src="/icons/icon-bike.svg"
                  alt="Cycling icon"
                  width={40}
                  height={40}
                  className="object-contain slostr-logo"
                />
              </div>
              <p className="mt-2 text-base text-[#2a2d2e]">{t("bike-itineraries-explanation")}</p>
            </div>
            </Link>

          {/* Hiking */}
          <Link to={"/itineraries/hiking"} className="shadow-lg rounded-[15px] p-5 hover:scale-105 transition-all">
            <div className="flex flex-col items-center cursor-pointer w-[350px]">
              <div className="w-[80px] h-[80px] md:w-[100px] md:h-[100px] flex justify-center items-center bg-primary rounded-full">
                <img
                  src="/icons/icon-hike.svg"
                  alt="Hiking icon"
                  width={40}
                  height={40}
                  className="object-contain slostr-logo"
                />
              </div>
              <p className="mt-2 text-base text-[#2a2d2e]">{t("hiking-itineraries-explanation")}</p>
            </div>
          </Link>

          {/* Climbing */}
          <Link to={"/climbing-spots"} className="shadow-lg rounded-[15px] p-5 hover:scale-105 transition-all">
            <div className="flex flex-col items-center cursor-pointer w-[350px]">
              <div className="w-[80px] h-[80px] md:w-[100px] md:h-[100px] flex justify-center items-center bg-primary rounded-full">
                <img
                  src="/icons/icon-climbing.svg"
                  alt="Climbing icon"
                  width={40}
                  height={40}
                  className="object-contain slostr-logo"
                />
              </div>
              <p className="mt-2 text-base text-[#2a2d2e]">{t("climbing-spots-explanation")}</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Maps;
