import ImageGallery from "react-image-gallery";


export const formatDate = (isoString: any) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    return date.toLocaleDateString();
  };

export function transformChapterImages(chapter: any) {
    if (!chapter) return [];

    const images =
      chapter.images?.map((image: any) => ({
        type: "image",
        original: image.image_url || image.image_file,
        caption: image.caption || "",
      })) || [];

    const videos =
      chapter.videos?.map((video: any) => ({
        type: "video",
        original: video.video_url || video.video_file,
        caption: video.caption || "",
      })) || [];

    return [...images, ...videos];
  }

  export const MyGallery: React.FC<any> = ({ items, isFullscreen, setIsFullscreen }) => {

    const handleScreenChange = (fullScreen: any) => {
      setIsFullscreen(fullScreen);
    };

    const renderItem = (item: any) => {
      if (item.type === "video") {
        return (
          <div className="image-gallery-image flex justify-start items-start">
            <video
              className="overflow-auto object-contain"
              controls
              style={{
                maxHeight: isFullscreen ? "100vh" : "300px",
                height: isFullscreen ? "auto" : "300px",
                width: "100%",
                maxWidth: "100%",
                margin: "auto",
                display: "block",
              }}
            >
              <source
                src={item.original}
                type="video/mp4"
                className="h-full w-full"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      }

      return (
        <div className="image-gallery-image flex justify-start items-start">
          <img
            src={item.original}
            alt={item.description}
            className="overflow-auto object-contain"
            style={{
              maxHeight: isFullscreen ? "100vh" : "300px",
              height: isFullscreen ? "auto" : "300px",
              width: "100%",
              maxWidth: "100vw",
              margin: "auto",
              display: "block",
            }}
          />
          {item.caption && (
            <span
              className="image-gallery-description"
              style={{ color: "white" }}
            >
              {item.caption}
            </span>
          )}
        </div>
      );
    };

    return (
      // <div className="w-full" style={{ height: isFullscreen ? '100vh' : '300px' }}>
      <ImageGallery
        items={items}
        renderItem={renderItem}
        onScreenChange={handleScreenChange}
        useBrowserFullscreen={false}
        showBullets={true}
        showPlayButton={false}
      />
      // </div>
    );
  };
